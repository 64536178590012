<template>
  <v-card>
    <v-card-title>
      <v-icon>
          {{ icons.mdiOfficeBuilding }}
        </v-icon>
          <span class="me-3">&nbsp; Meus Imóveis</span>
          <v-spacer></v-spacer>
          <!-- <span class="text-xs text--disabled cursor-pointer">Ver todas</span> -->
        </v-card-title>

    <v-data-table
    v-if="this.showTable"
      :headers="headers"
      :items="this.empreendimentosresumo"
      item-key="obra"
      class="table-rounded"
      hide-default-footer
      disable-sort
      fixed-header
      no-data-text="Nenhum imóvel encontrado"
      no-results-text="Nenhum imóvel encontrado"
    >
      <!-- name -->
      <template #[`item.id`]="{item}">
        <v-icon>
              {{ icons.mdiOfficeBuildingMarkerOutline }}
            </v-icon>
        <div class="d-flex flex-column">
          <small>{{ item.post }}</small>
        </div>
      </template>
      <!-- Valor -->
      <template #[`item.valor`]="{item}">
        <v-chip
          small
          class="font-weight-medium"
        >
          {{ item.valor }}
        </v-chip>
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[status[item.status]]"
          class="font-weight-medium"
        >
          {{ status[item.status] }}
        </v-chip>
      </template>
      <!-- Ação -->
      <template #[`item.acoes`]="{item}">
        <v-btn
            style="height: 20px !important;width: 90px !important;"
            small
            icon
            color="white"
            class="btn-bol text-center"
            @click="verDocumentos(item.obraId)"
          > <small>Documentos</small> <small style="visibility: hidden; position: absolute;">{{ item.acoes }}</small>
          </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiOfficeBuilding, mdiOfficeBuildingMarkerOutline, mdiEyeOutline } from '@mdi/js'

import Cookie from 'js-cookie'
import Strings from '@/common/strings'
import UsuarioStore from './../../store/Usuario'

export default {
  mounted: function(){

    this.$loading(true)

    UsuarioStore.resumovenda(JSON.parse(Cookie.get('payloadEmpreendimentos')), Strings.imobiliariaId).then(result => {

      result.data.forEach((val, ind) =>{
          var payload = {
            responsive_id: '',
            id: ind,
            obraId: val.ObraId,
            empresa: val.codigoEmpresa,
            numeroVenda: val.numeroVenda,
            obra: val.codigoObra,
            empreendimento: val.desc_obra,
            identificador: val.identificador,
            totalparcelas: val.totalParcelas,
            quantidadeParcelaPaga: val.parcelasportipo[0]?.quantidadeParcelaPaga,
            /*
            valor: val.totalAPagarComDesconto.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            */
            acoes: '',
        }
        this.empreendimentosresumo.push(payload)
      })

      this.showTable = true

      this.$loading(false)
    }).catch(error => {

      console.log(error)
      if(error.response.status == 401){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
            }
    }).finally(() =>{
      this.$loading(false)
    })

  },
  methods: {
    verParcelas(empresa, num_venda, obra, empreendimento, identificador){
      this.$router.push({name: 'parcelas', params: { empresa: empresa, num_venda: num_venda, obra: obra, empreendimento: empreendimento, identificador: identificador}})
    },
    verDocumentos(obraId){
      this.$router.push({name: 'documentos', params: { object: "obra", obraId: obraId}})
    }
  },
  setup() {
    const empreendimentosresumo = []
    const showTable = false
    const statusColor = {
      /* eslint-disable key-spacing */
      Avencer: 'primary',
      Pago: 'success',
      Vencido: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: '#', value: 'id' },
        { text: 'Obra', value: 'obra' },
        { text: 'Empreendimento', value: 'empreendimento' },
        { text: 'Identificador', value: 'identificador'},
        { text: 'Total Parcelas', value: 'totalparcelas' },
        { text: 'Parcelas Pagas', value: 'quantidadeParcelaPaga' },
        { text: 'Ações', value: 'acoes' },
      ],
      status: {
        1: 'Pago',
        2: 'Vencido',
        3: 'Avencer',
      },
      statusColor,

      // icons
      icons: {
        mdiOfficeBuilding,
        mdiOfficeBuildingMarkerOutline,
        mdiEyeOutline
      },
      empreendimentosresumo,
      showTable
    }
  },
}
</script>
<style scoped>
.btn-bol{
  border-radius: 40px !important;
  width: 5rem !important;
  background: rgb(0, 136, 255);
  height: 1.6rem !important;
}
</style>
