<template>
    <v-row>
        <v-col
        cols="12"
        md="12">
            <table-empreendimentos></table-empreendimentos>
        </v-col>
    </v-row>
</template>

<script>

import TableEmpreendimentos from './TableEmpreendimentos.vue'

export default {
    components: {
        TableEmpreendimentos,
    }
}
</script>